import React, { memo, useEffect } from "react";
import {
  TextField,
  Autocomplete,
  IconButton,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { formStyle } from "../../../theming/formStyle";
import { TbTruckDelivery, TbCircleCheck } from "react-icons/tb";
import { AiFillDelete } from "react-icons/ai";
import { Button } from "react-bootstrap";
import ToolTip from "../ToolTip";
import ErrorIcon from "../ErrorIcon/ErrorIcon";
import ConfirmationAlertBySteps from "../ConfirmationAlertBySteps";
import ConfirmationAlert from "../ConfirmationAlert/ConfirmarionAlert";
import useItem from "../../../hooks/useItem";
import useItemButtons from "../../../hooks/useItemButtons";
import "./InventoryOrderItem.scss";
import InputNumber from "../InputNumber";

import { useState } from "react";
import { useSelector } from "react-redux";
import SubstitutionItemPopup from "../SubstitutionItemPopup/SubstitutionItemPopup";
import {
  ORDER_ITEM_ADDON_TYPE,
  ORDER_ITEM_DESCRIPTION_TYPE,
  ORDER_ITEM_MOUNT_TYPE,
} from "../../../helpers/const";

function InventoryOrderItem({ item, allowedActions }) {
  // hooks
  const {
    onKeyDownHandler,
    setProductCode,
    setPrice,
    setDError,
    onClickHandler,
    handleWhChange,
    handleSChange,
    handleDChange,
    handleTqChange,
    handleQrdChange,
    handleBoChange,
    handlePriceChange,
    handleProductChange,
    handleItemSubs,
    checkPriceOverride,
    handleManufacturedItems,
    getAvailableWarehouses,
    getDiscountSchedules,
    setSubstitutionValue,
    setOldPrice,
    price,
    refCode,
    productError,
    productCode,
    dError,
    tqError,
    qrdError,
    boError,
    itemPriceError,
    quoteId,
    substitutionItemArray,
    subsCountRef,
    productRef,
    substitutionValue,
    whInputRef,
    sInputRef,
    maxSubsRef,
    dInputRef,
    tqInputRef,
    qrdInputRef,
    boInputRef,
    priceInputRef,
    oldPrice,
  } = useItem({ item });

  const [doManufacturing, setDoManufacturing] = useState(false);
  const [isManufactured, setIsManufactured] = useState(false);
  const [itemSubsFound, setItemSubsFound] = useState(false);
  const [showSubstitutionPopup, setShowSubstitutionPopup] = useState(false);
  const [selectedProductCode, setSelectedProductCode] = useState(false);

  const { customerId } = useSelector((state) => state.customer);

  const oldValueRef = React.useRef(-5);
  const [countState, setCountState] = useState(1);
  const [oldProductSearch, setOldProductSearch] = useState("");
  const [oldDescriptionSearch, setOldDesriptionSearch] = useState("");

  useEffect(() => {
    if (subsCountRef.current > 0) {
      setItemSubsFound(true);
    } else {
      setItemSubsFound(false);
    }
  }, [substitutionItemArray]);

  useEffect(() => {
    if (doManufacturing === true) {
      if (
        ["75", "76"].includes(productCode.substring(0, 2)) &&
        item.qrd > 0 &&
        customerId > 0 &&
        isManufactured === false
      ) {
        const res = handleManufacturedItems(productCode, isManufactured);
        if (res) setIsManufactured(true);
      }
    }
  }, [doManufacturing]);

  const {
    deletingProcess,
    deleteItem,
    setShowDeleteItemPopup,
    deletionMessages,
    showDeleteItemPopup,
    mountOn,
  } = useItemButtons(item);

  useEffect(() => {
    if (
      selectedProductCode === true ||
      (price > 0 &&
        productCode !== "" &&
        !["75", "76"].includes(productCode.substring(0, 2)))
    ) {
      handleItemSubs(
        productCode,
        item.d,
        price,
        item.itemType,
        "S",
        null,
        item.warehouse_id
      );
      setSelectedProductCode(false);
      if (price !== oldPrice) {
        var temp = oldPrice;
        setOldPrice(0);
        setOldPrice(temp);
      }
    }
  }, [price]);

  function getDisabled(item) {
    if (item.productCode) {
      if (item.productCode.length < 1 || item.isLocked) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }

  const filterOptions = (options, params) => {
    const { inputValue } = params;

    if (inputValue.length > 1) {
      setOldProductSearch(inputValue);
      return options.filter((option) =>
        option.product_code.toLowerCase().startsWith(inputValue.toLowerCase())
      );
    } else if (oldProductSearch) {
      return options.filter((option) =>
        option.product_code
          .toLowerCase()
          .startsWith(oldProductSearch.toLowerCase())
      );
    } else {
      return options.slice(0, 50);
    }
  };

  const filterOptionsDesc = (options, params) => {
    const { inputValue } = params;
    if (inputValue.length > 1) {
      setOldDesriptionSearch(inputValue);
      return options.filter((option) =>
        option.description.toLowerCase().includes(inputValue.toLowerCase())
      );
    } else if (oldProductSearch) {
      return options.filter((option) =>
        option.description
          .toLowerCase()
          .includes(oldDescriptionSearch.toLowerCase())
      );
    } else {
      return options.slice(0, 50);
    }
  };

  function decIndex() {
    setCountState((prevValue) => prevValue - 1);
    if (countState < 2) {
      setCountState(maxSubsRef.current);
    }
  }

  function incIndex() {
    setCountState((prevValue) => prevValue + 1);
    if (countState > maxSubsRef.current - 1) {
      setCountState(1);
    }
  }

  function handleSubstitution() {
    setSubstitutionValue(
      substitutionItemArray[countState - 1].additionalPercOff
    );
    setShowSubstitutionPopup(false);
    setProductCode(substitutionItemArray[countState - 1].substitutionCode);
    handleProductChange(
      substitutionItemArray[countState - 1].substitutionCode,
      true,
      substitutionItemArray[countState - 1].cost
    );
    handleItemSubs(substitutionItemArray[countState - 1].substitutionCode);
  }

  function handleRadioClick(event) {
    var count = parseInt(event.target.id) + 1;
    setCountState(count);
  }

  return (
    <>
      <div className="whole">
        <table className="wrapper" style={{ width: "100%", height: 83 }}>
          {/* Start the first row */}
          <table class="ts-1">
            <thead>
              <th>Ln</th>
              <th>Product#</th>
              <th>Description</th>
            </thead>
            <tbody class="tb-1">
              <tr style={{ width: "100%" }}>
                <td
                  className="align-middle bg"
                  style={{ maxHeight: "90", width: "8%" }}
                >
                  <strong>{item.ln}</strong>
                </td>
                <td className="ts-1-1">
                  {refCode && (
                    <ToolTip title={refCode}>
                      <span>
                        <InfoOutlinedIcon className="mt-4" />
                      </span>
                    </ToolTip>
                  )}
                  <Autocomplete
                    disableClearable
                    className="autocomplete-input w-80"
                    options={item.availableProducts}
                    name="productCode"
                    disabled={item.isLocked}
                    value={item.productCode}
                    filterOptions={filterOptions}
                    getOptionLabel={(option) => {
                      return option.product_code
                        ? option.product_code
                        : item.productCode
                        ? item.productCode
                        : "";
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="w-100"
                        {...params}
                        style={formStyle.inputStyle}
                        InputLabelProps={formStyle.inputLabelProps}
                        error={productError.error}
                        inputRef={productRef}
                        onKeyDown={(event) =>
                          onKeyDownHandler(event, whInputRef)
                        }
                        onChange={(_e) => {
                          setProductCode(_e.target.value);
                        }}
                        onClick={onClickHandler}
                      />
                    )}
                    openOnFocus
                    onChange={(_e, value) => {
                      setItemSubsFound(false);
                      handleProductChange(value, false);
                      setSelectedProductCode(true);
                    }}
                  />
                </td>
                <td className="align-middle">
                  <Autocomplete
                    disableClearable
                    className="autocomplete-input w-100"
                    options={item.availableProducts}
                    name="description"
                    disabled={item.isLocked}
                    value={item.description}
                    filterOptions={filterOptionsDesc}
                    getOptionLabel={(option) => {
                      return option.description
                        ? option.description
                        : item.description
                        ? item.description
                        : "";
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="description-line w-100"
                        {...params}
                        style={formStyle.inputStyle}
                        InputLabelProps={formStyle.inputLabelProps}
                        error={productError.error}
                        onKeyDown={(event) =>
                          onKeyDownHandler(event, whInputRef)
                        }
                        onClick={onClickHandler}
                        multiline
                        rows={2}
                      />
                    )}
                    openOnFocus
                    onChange={(_e, value) => {
                      handleProductChange(value, false);
                      setSelectedProductCode(true);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  {productError.error && (
                    <ToolTip
                      title={productError.msg}
                      textColor="#fff"
                      backgroundColor="#d32f2f"
                    >
                      <span>
                        <ErrorIcon />
                      </span>
                    </ToolTip>
                  )}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          {/* End first row

          Start second row
      */}
          <table class="ts-2">
            <thead>
              <th>Wh</th>
              <th>S</th>
              <th>D</th>
            </thead>
            <tbody class="tb-2">
              <tr>
                <td>
                  <Autocomplete
                    disableClearable
                    className="autocomplete-input"
                    disabled={getDisabled(item)}
                    options={item.warehouses.map((warehouse) => {
                      return {
                        label:
                          warehouse.warehouse_name +
                          ":" +
                          warehouse.qty_available,
                        name: warehouse.warehouse_name,
                        id: warehouse.warehouse_id,
                        productAvailability: warehouse.qty_available,
                      };
                    })}
                    onFocus={() => {
                      if (!item.warehouses?.length) {
                        getAvailableWarehouses();
                      }
                    }}
                    name="wh"
                    style={{ width: 66 }}
                    value={item.wh.name}
                    openOnFocus
                    onChange={(e, value) => handleWhChange(value)}
                    noOptionsText={
                      item.loadingWarehouses ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        "No options"
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={formStyle.inputStyle}
                        InputLabelProps={formStyle.inputLabelProps}
                        inputRef={whInputRef}
                        onKeyDown={(event) =>
                          onKeyDownHandler(event, sInputRef)
                        }
                        onClick={onClickHandler}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    disableClearable
                    className="autocomplete-input"
                    disabled={getDisabled(item)}
                    value={item.s}
                    name="s"
                    style={{ width: 60 }}
                    onChange={(e, value) => handleSChange(value)}
                    options={[
                      {
                        label: "",
                        name: "",
                      },
                      {
                        label: "D",
                        name: "D",
                      },
                      {
                        label: "S",
                        name: "S",
                      },
                    ]}
                    onFocus={() => {
                      if (!item.availableDiscounts?.length) {
                        getDiscountSchedules();
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="s"
                        style={formStyle.inputStyle}
                        InputLabelProps={formStyle.inputLabelProps}
                        inputRef={sInputRef}
                        onKeyDown={(event) =>
                          onKeyDownHandler(event, dInputRef)
                        }
                        onClick={onClickHandler}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    disableClearable
                    disabled={item.isLocked || item.s !== "D"}
                    className="autocomplete-input"
                    options={item?.availableDiscounts}
                    name="d"
                    style={{ width: 60 }}
                    value={item.d}
                    onFocus={() => {
                      if (!item.availableDiscounts?.length) {
                        getDiscountSchedules();
                      }
                    }}
                    onChange={(_e, value) => handleDChange(value)}
                    noOptionsText={
                      item.loadingDiscountSchedules ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        "No options"
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={formStyle.inputStyle}
                        InputLabelProps={formStyle.inputLabelProps}
                        inputRef={dInputRef}
                        error={dError.error}
                        onKeyDown={(event) =>
                          onKeyDownHandler(event, tqInputRef)
                        }
                        onClick={onClickHandler}
                        onBlur={(e) => {
                          setDError({ error: false, msg: "" });
                        }}
                        onChange={(e) => {
                          if (
                            !item?.availableDiscounts.includes(
                              e.currentTarget.value.toUpperCase()
                            )
                          ) {
                            setDError({
                              error: true,
                              msg: "Enter a valid value that is in the discount list",
                            });
                          } else {
                            setDError({ error: false, msg: "" });
                          }
                        }}
                      />
                    )}
                    openOnFocus
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  {dError.error && (
                    <ToolTip
                      title={dError.msg}
                      textColor="#fff"
                      backgroundColor="#d32f2f"
                    >
                      <span>
                        <ErrorIcon />
                      </span>
                    </ToolTip>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="ts-3">
            <thead>
              <th>TQ</th>
              <th>Qrd</th>
              <th>B/O</th>
              <th>Avail</th>
              <th>Price</th>
              <th>EXT</th>
            </thead>
            <tbody class="tb-3">
              <tr>
                <td style={{ maxWidth: 60 }}>
                  <InputNumber
                    id="filled-basic"
                    name="tq"
                    disabled={getDisabled(item)}
                    value={item.tq}
                    onChange={(e) => {
                      handleTqChange(parseInt(e.target.value));
                    }}
                    error={tqError.error}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    inputRef={tqInputRef}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, qrdInputRef, "tq")
                    }
                    onClick={onClickHandler}
                  />
                </td>
                <td style={{ maxWidth: 60 }}>
                  <InputNumber
                    id="filled-basic"
                    name="qrd"
                    disabled={getDisabled(item)}
                    value={item.qrd}
                    onChange={(e) => {
                      handleQrdChange(parseInt(e.target.value));
                      if (item.avail <= 0) {
                        oldValueRef.current = e.target.value;
                      } else if (e.target.value > item.avail) {
                        oldValueRef.current = Math.abs(
                          item.avail - e.target.value
                        );
                      }
                    }}
                    error={qrdError.error}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    inputRef={qrdInputRef}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, boInputRef, "qrd")
                    }
                    onClick={onClickHandler}
                  />
                </td>
                <td style={{ maxWidth: 60 }}>
                  <InputNumber
                    id="filled-basic"
                    disabled={getDisabled(item)}
                    defaultValue={0}
                    value={item.bo}
                    name="bo"
                    onChange={(e) => {
                      const oldValue = parseInt(oldValueRef.current);
                      let newValue = e.target.value;
                      if (item.qrd < newValue) {
                        newValue = item.qrd;
                      }
                      handleBoChange(parseInt(newValue), oldValue);

                      if (newValue === "") {
                        oldValueRef.current = 0;
                      } else {
                        oldValueRef.current = newValue;
                      }
                    }}
                    error={boError.error}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                    inputRef={boInputRef}
                    onKeyDown={(event) =>
                      onKeyDownHandler(event, priceInputRef, "bo")
                    }
                    onClick={onClickHandler}
                  />
                </td>
                <td style={{ maxWidth: 100 }}>
                  <InputNumber
                    id="filled-basic"
                    name="avail"
                    className="input-read-only"
                    disabled={true}
                    value={item.avail}
                  />
                </td>
                <td style={{ maxWidth: 100 }}>
                  <InputNumber
                    id="filled-basic"
                    name="item_price"
                    disabled={
                      item.s === "D" || getDisabled(item) ? true : false
                    }
                    className={item.s !== "S" ? "input-read-only" : ""}
                    InputProps={
                      item.s !== "S"
                        ? {
                            readOnly: true,
                          }
                        : {}
                    }
                    // value={item?.item_price ? item.item_price.toFixed(2) : item.item_price}
                    value={price}
                    onBlur={(e) => {
                      if (parseFloat(e.target.value) !== 0) {
                        checkPriceOverride(parseFloat(e.target.value));
                        handlePriceChange(parseFloat(e.target.value));
                        setDoManufacturing(true);
                      }
                    }}
                    error={itemPriceError.error}
                    //onChange={(e) => handlePriceChange(e.target.value)}
                    onChange={(e) => setPrice(e.target.value)}
                    inputRef={priceInputRef}
                    onKeyDown={(event) => onKeyDownHandler(event)}
                    onClick={onClickHandler}
                  />
                </td>
                <td style={{ maxWidth: 90 }}>
                  <InputNumber
                    id="filled-basic"
                    name="ext"
                    disabled={true}
                    className="input-read-only"
                    value={item.ext.toFixed(2)}
                    InputProps={{ readOnly: true }}
                    style={{ width: 84 }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {tqError.error && (
                    <ToolTip
                      title={tqError.msg}
                      textColor="#fff"
                      backgroundColor="#d32f2f"
                    >
                      <span>
                        <ErrorIcon />
                      </span>
                    </ToolTip>
                  )}
                </td>
                <td>
                  {qrdError.error && (
                    <ToolTip
                      title={qrdError.msg}
                      textColor="#fff"
                      backgroundColor="#d32f2f"
                    >
                      <span>
                        <ErrorIcon />
                      </span>
                    </ToolTip>
                  )}
                </td>
                <td>
                  {boError.error && (
                    <ToolTip
                      title={boError.msg}
                      textColor="#fff"
                      backgroundColor="#d32f2f"
                    >
                      <span>
                        <ErrorIcon />
                      </span>
                    </ToolTip>
                  )}
                </td>
                <td>
                  {itemPriceError.error && (
                    <ToolTip
                      title={itemPriceError.msg}
                      textColor="#fff"
                      backgroundColor="#d32f2f"
                    >
                      <span>
                        <ErrorIcon />
                      </span>
                    </ToolTip>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="ts-4">
            <thead>
              <th>Weight</th>
              <th>w/surch</th>
            </thead>
            <tbody>
              <td style={{ maxWidth: 90 }}>
                <InputNumber
                  id="filled-basic"
                  name="weight"
                  disabled={true}
                  value={item.weight}
                  className="input-read-only"
                  InputProps={{ readOnly: true }}
                />
              </td>
              <td style={{ maxWidth: 90 }}>
                <InputNumber
                  id="filled-basic"
                  name="wsurch"
                  disabled={true}
                  className="input-read-only"
                  value={item.wsurch.toFixed(2)}
                  InputProps={{ readOnly: true }}
                  style={{ width: 84 }}
                />
              </td>
            </tbody>
          </table>
          <table class="ts-5">
            <thead>
              <th className="order-item-icons"></th>
              <th className="order-item-icons"></th>
              <th className="order-item-icons"></th>
            </thead>
            <tbody>
              <td>
                <ButtonGroup aria-label="action-button-group" className="mt-4">
                  <Tooltip title="Mount" arrow placement="top">
                    <IconButton
                      color="primary"
                      onClick={(_e) => mountOn(item)}
                      disabled={
                        quoteId ||
                        (!allowedActions.mt && item.isLocked) ||
                        item.productCode === "" ||
                        [
                          ORDER_ITEM_MOUNT_TYPE,
                          ORDER_ITEM_ADDON_TYPE,
                          ORDER_ITEM_DESCRIPTION_TYPE,
                        ].includes(item.itemType)
                      }
                      size="small"
                    >
                      <TbTruckDelivery />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" arrow placement="top">
                    <IconButton
                      color="primary"
                      onClick={() => deletingProcess()}
                      disabled={
                        quoteId || (!allowedActions.delete && item.isLocked)
                      }
                      size="small"
                    >
                      <AiFillDelete />
                    </IconButton>
                  </Tooltip>
                </ButtonGroup>
              </td>
            </tbody>
          </table>
        </table>
        <div className="item-sub-alert" hidden={!itemSubsFound}>
          <TbCircleCheck className="tbCircleCheck" />
          <div className="item-subs-text">
            Item substitution found for product code: {item.productCode}
          </div>
          <Button
            className="item-subs-btn"
            onClick={() => setShowSubstitutionPopup(true)}
          >
            <span className="span-btn">View Item Substitutions</span>
          </Button>
        </div>
        {showSubstitutionPopup && (
          <SubstitutionItemPopup
            count={countState}
            max={maxSubsRef.current}
            content={substitutionItemArray}
            show={showSubstitutionPopup}
            backEvent={() => decIndex()}
            forwardEvent={() => incIndex()}
            handleCancelEvent={() => setShowSubstitutionPopup(false)}
            handleConfirmEvent={() => handleSubstitution(null)}
            handleRadioClick={(event) => handleRadioClick(event)}
          />
        )}
      </div>
    </>
  );
}

export default memo(InventoryOrderItem, (prevProps, nextProps) => {
  if (
    prevProps.item.id === nextProps.item.id &&
    prevProps.item.ln === nextProps.item.ln &&
    prevProps.item.itemType === nextProps.item.itemType &&
    prevProps.item.isLocked === nextProps.item.isLocked &&
    prevProps.item.productCode === nextProps.item.productCode &&
    prevProps.item.availableProducts === nextProps.item.availableProducts &&
    prevProps.item.warehouses === nextProps.item.warehouses &&
    prevProps.item.wh.id === nextProps.item.wh.id &&
    prevProps.item.s === nextProps.item.s &&
    prevProps.item.availableDiscounts === nextProps.item.availableDiscounts &&
    prevProps.item.d === nextProps.item.d &&
    prevProps.item.tq === nextProps.item.tq &&
    prevProps.item.qrd === nextProps.item.qrd &&
    prevProps.item.bo === nextProps.item.bo &&
    prevProps.item.avail === nextProps.item.avail &&
    prevProps.item.ext === nextProps.item.ext &&
    prevProps.item.weight === nextProps.item.weight &&
    prevProps.item.wsurch === nextProps.item.wsurch &&
    prevProps.item.wsurch === nextProps.item.wsurch &&
    prevProps.item.item_price === nextProps.item.item_price &&
    prevProps.item.errors.product === nextProps.item.errors.product &&
    prevProps.item.errors.s === nextProps.item.errors.s &&
    prevProps.item.errors.d === nextProps.item.errors.d &&
    prevProps.item.errors.tq === nextProps.item.errors.tq &&
    prevProps.item.errors.qrd === nextProps.item.errors.qrd &&
    prevProps.item.errors.bo === nextProps.item.errors.bo &&
    prevProps.item.errors.itemPrice === nextProps.item.errors.itemPrice &&
    prevProps.item.loadingProducts === nextProps.item.loadingProducts &&
    prevProps.item.loadingWarehouses === nextProps.item.loadingWarehouses &&
    prevProps.item.loadingDiscountSchedules ===
      nextProps.item.loadingDiscountSchedules &&
    prevProps.allowedActions.mt === nextProps.allowedActions.mt &&
    prevProps.allowedActions.delete === nextProps.allowedActions.delete
  ) {
    return true;
  }
  return false;
});
