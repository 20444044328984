/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { AiOutlineClear, AiOutlineSave, AiFillDelete } from "react-icons/ai";
import AdfScannerIcon from '@mui/icons-material/AdfScanner';

import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import HoldDate from "../HoldDate/HoldDate";

import "./SubHeading.scss"

import SubOrderSelector from "../../atoms/SubOrderSelector";
import QuoteSelector from "../../atoms/QuoteSelector";
import ARBalanceMessage from "../../atoms/ARBalanceMessage";
import BackOrderMessage from "../../atoms/BackOrderMessage";

import { formatErrorMessage } from "../../../helpers/helperFunctions";

import { setCleaningAll } from "../../../store/slices/headerSlice";
import {
  setErrors,
  resetSubmitProcess,
} from "../../../store/slices/validations/validationsSlice";
import { createQuote, resetQuoteId } from "../../../store/slices/quoteSlice";
import { checkBackordersProcess, deleteItems } from "../../../store/slices/item/itemSlice";
import { endpoints } from "../../../api/endpoints";

import {
  createSubOrder,
  updateSubOrder,
  saveAsOrder,
  deleteSubOrder,
  setOrderStatus,
  setOrderHoldStatus,
  setCheckDuplicatedDisabled,
  resetSubOrderId,
  printBol,
  printToOffice,
} from "../../../store/slices/subOrderSlice";

import {
  ORDER_STATUS,
  ORDER_HOLD_STATUS,
  COMPLETED_ORDER_STATUS,
  INVOICED_ORDER_STATUS,
  POSTED_ORDER_STATUS,
  OPEN_ORDER_STATUS,
  PENDING_ORDER_STATUS,
  DATE_ORDER_HOLD_STATUS,
  PRICE_ORDER_HOLD_STATUS,
  APPROVED_ORDER_STATUS,
} from "../../../helpers/const";

import ConfirmationAlert from "../../atoms/ConfirmationAlert/ConfirmarionAlert";
import ConfirmationAlertBySteps from "../../atoms/ConfirmationAlertBySteps";
import { AlertMessageContext } from "../../atoms/AlertMessage/AlertMessage";
import { formStyle } from "../../../theming/formStyle";
import FaxEmail from "../../atoms/FaxEmail/FaxEmail";
import { resetCustomer } from "../../../store/slices/customer/customerSlice";
import PrintItemPopup from "./PrintPopup";
import { doInvoiceAction } from "../../../store/slices/AccountsReceivable/InvoiceAccessSlice";

function SubHeading() {
  // Local states
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [statusLabel, setStatusLabel] = useState("");
  const [holdStatusLabel, setHoldStatusLabel] = useState("");
  const [openSaveAsOrderConfirmation, setOpenSaveAsOrderConfirmation] =
    useState(false);
  const [openDeleteOrderConfirmation, setOpenDeleteOrderConfirmation] =
    useState(false);
  const [deletionMessages, setDeletionMessages] = useState([]);
  const [registerType, setRegisterType] = useState("subOrder");
  const [commentDate, setCommentDate] = useState(dayjs());
  const [customerComment, setCustomerComment] = useState("");
  const [showPrintPopup, setShowPrintPopup] = useState(false)
  const [selectedPrint, setSelectedPrint] = useState("order")
  // Redux states
  const { customerId, customerName } = useSelector((state) => state.customer);
  const { items, allItemsToDelete } = useSelector((state) => state.items);
  const { savedAsQuote, savedAsOrder } = useSelector((state) => state.header);
  const { quoteId } = useSelector((state) => state.quote);

  const { billOfLading, freightAmount, handlingCharge } = useSelector(
    (state) => state.freight,
  );
  const {
    autoSaveOrder: { shouldAutosave },
  } = useSelector((state) => state.eventHandler);

  const {
    subOrder,
    subOrder: {
      status: orderStatus,
      hold_status: hold_status,
      took_by,
      warehouse_name,
      placed_by,
      customer_po,
      warehouse_id,
      customer_invoice_num,
    },
    subOrderId,
    subOrdersLocked,
  } = useSelector((state) => state.subOrder);

  const {
    submitProcess,
    hasItemsErrors,
    hasCustomerErrors,
    hasOrderDetailsErrors,
    hasWhError,
    hasFreightError,
  } = useSelector((state) => state.validations);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Context
  const alertNotification = useContext(AlertMessageContext);

  useEffect(() => {
    var status = ORDER_STATUS.find((os) => os.id === orderStatus);

    setStatusLabel(status.label);
  }, [orderStatus]);

  useEffect(() => {
    var holdStatus = ORDER_HOLD_STATUS.find((os) => os.id === hold_status);

    setHoldStatusLabel(holdStatus.label);
  }, [hold_status]);

  /*
 * Function to handle the status of the delete confirmation popup
 * and if the confirmDeletion is true it will call a delete request of the subOrder
 * @param {boolean} state - State of the popup
 * @param {boolean} confirmDeletion
 */
  const setOpenDeleteOrderConfirmationState = useCallback(
    (state, confirmDeletion) => {
      setOpenDeleteOrderConfirmation(state);
      if (confirmDeletion) {
        dispatch(deleteSubOrder(subOrderId)).then((response) => {
          if (!response?.error) {
            alertNotification.handleOpen(
              "success-deleting-a-sub-order",
              "success",
              "Order deleted successfully",
              5000,
            );

            dispatch(resetCustomer());
            dispatch(resetSubOrderId());
            navigate(endpoints.ORDER_ENTRY);
          }
        });
      }
    },
    [dispatch, subOrderId],
  );


  const handleSendInvoice = (value) => {
    setShowPrintPopup(false)
    console.log(value)
    dispatch(doInvoiceAction({
      transactionNum: customer_invoice_num,
      emailAddress: value,
      deliveryMethod:"email",
      customerNum: customerId,
      useOverride: true
    })).then(response => {
      if (!response?.error) {
        alertNotification.handleOpen(
          "validation-customer-widget",
          "success",
          `Invoice sent to '${value}' successfully!`,
          2000,
        );
      }
    })
  }

  /**
   * Show messages of error when the fields of the form
   * haven't been filled
   */
  useEffect(() => {
    if (submitProcess) {
      dispatch(resetSubmitProcess());

      if (hasCustomerErrors) {
        alertNotification.handleOpen(
          "validation-customer-widget",
          "danger",
          "please select a customer",
          10000,
        );
      }
      if (hasOrderDetailsErrors) {
        alertNotification.handleOpen(
          "validation-order-details",
          "danger",
          "Inputs require adjustment in order details section",
          10000,
        );
      }
      if (hasItemsErrors || hasWhError) {
        alertNotification.handleOpen(
          "validation-order-items",
          "danger",
          "Inputs require adjustment in order items section",
          5000,
        );
      }
      if (hasFreightError) {
        alertNotification.handleOpen(
          "validation-freight-details",
          "danger",
          "Inputs require adjustment in order freight section",
          10000,
        );
      }

      if (
        !hasCustomerErrors &&
        !hasOrderDetailsErrors &&
        !hasItemsErrors &&
        !hasWhError &&
        !hasFreightError
      ) {
        if (subOrderId === 0 && registerType === "subOrder") {

          dispatch(
            createSubOrder({
              comment: customerComment,
              date: dayjs(commentDate).format("YYYY-MM-DD"),
            }),
          ).then((response) => {
            if (!response?.error) {
              if (response.payload.customMsg === 1) {
                alertNotification.handleOpen(
                  "create-suborder-success",
                  "success",
                  "Order Saved",
                  5000,
                )
              } else if (response.payload.customMsg === 2) {
                alertNotification.handleOpen(
                  "create-suborder-successs",
                  "danger",
                  "Order saved with errors. There is an issue with the order cost.",
                  5000,
                )
              }

              if (response.payload.customMsg === 1) {
                dispatch(printBol()).then((response) => {
                  if (!response?.error && response.payload.status == 1) {
                    alertNotification.handleOpen(
                      "success-savign-as-sub-order",
                      "success",
                      "Bol Printed Successfully!",
                      5000,
                    )
                  }
                })
              } else {
                alertNotification.handleOpen(
                  "create-suborder-successs",
                  "warning",
                  "Bol not printed to warehouse.",
                  5000,
                )
              }

              navigate(endpoints.ORDER_ENTRY + "/order/" + response.payload.id);

            }
          });
        } else if (subOrderId && registerType === "subOrder") {

          if (allItemsToDelete.length > 0) dispatch(deleteItems(allItemsToDelete))

          dispatch(updateSubOrder()).then((response) => {

            if (!response?.error && response.payload.customMsg !== 2) {
              alertNotification.handleOpen(
                "update-suborder-success",
                "success",
                "Order saved",
                5000,
              );
            } else if (response.payload.customMsg === 2) {
              alertNotification.handleOpen(
                "create-suborder-successs",
                "danger",
                "Order saved with errors. There is an issue with the order cost.",
                5000,
              )
            }
            if (response.payload.customMsg === 1) {
              dispatch(printBol()).then((response) => {
                if (!response?.error && response.payload.customMsg !== 2) {
                  alertNotification.handleOpen(
                    "success-savign-as-sub-order",
                    "success",
                    "Bol Printed Successfully!",
                    5000,
                  )
                }
              })
            } else {
              alertNotification.handleOpen(
                "create-suborder-successs",
                "warning",
                "Bol not printed to warehouse.",
                5000,
              )
            }
            setTimeout(() => {
              window.location.reload()
            }, 2000)


          });
        } else if (subOrderId === 0 && registerType === "quote") {
          dispatch(createQuote()).then((response) => {
            if (!response?.error) {
              alertNotification.handleOpen(
                "create-quote-success",
                "success",
                "Quote saved",
                5000,
              );
              navigate(endpoints.ORDER_ENTRY + "/quote/" + response.payload);
            }
          });
        }
      }
    }
  }, [
    submitProcess,
    hasCustomerErrors,
    hasOrderDetailsErrors,
    hasItemsErrors,
    hasFreightError,
    alertNotification,
    subOrderId,
    hasWhError,
    subOrder,
    registerType,
    dispatch,
    navigate,
  ]);

  const office_options = ["Email", "Email and Print", "Print"]


  /**
   * Component function to render the status selector
   */
  const StatusSelector = () => {
    return (
      <>
        <table className="subh" style={{ float: "right" }}>
          <div className="d-flex">
            <tr className="wraps">

              <Dropdown>
                <Dropdown.Toggle
                  disabled={subOrdersLocked}
                  id={`drop-button-${statusLabel}`}
                  className="drop-status-button"
                >
                  <label >{statusLabel}</label>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {ORDER_STATUS.filter((s) => s.id !== OPEN_ORDER_STATUS.id).map(
                    (status, index) => (
                      <Dropdown.Item
                        key={status.id}
                        eventKey={index}
                        className="dropdown-draft"
                        onClick={() => dispatch(setOrderStatus(status.id))}
                        disabled={
                          [
                            COMPLETED_ORDER_STATUS.id,
                            INVOICED_ORDER_STATUS.id,
                            POSTED_ORDER_STATUS.id,
                          ].includes(status.id) ||
                          (status.id === OPEN_ORDER_STATUS.id && subOrderId)
                        }
                      >
                        {status.label}
                      </Dropdown.Item>
                    ),
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </tr>
            {orderStatus === PENDING_ORDER_STATUS.id && (
              <tr className="ms-2">
                <Dropdown>
                  <Dropdown.Toggle
                    id={`drop-button-${holdStatusLabel}`}
                    className="drop-status-button"
                  >
                    <label style={{ minWidth: 100 }}>{holdStatusLabel}</label>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {ORDER_HOLD_STATUS.map((status, index) => (
                      <Dropdown.Item
                        key={status.id}
                        eventKey={index}
                        className="dropdown-draft"
                        onClick={() => dispatch(setOrderHoldStatus(status.id))}
                      >
                        {status.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </tr>
            )}
          </div>
          {subOrder.hold_status === DATE_ORDER_HOLD_STATUS.id &&
            subOrder.status === PENDING_ORDER_STATUS.id && (
              <div className="mt-5">
                <HoldDate holdDate={subOrder.hold_date} />
              </div>
            )}
        </table>
      </>
    );
  };





  /**
   * Function to handle the status of the saves as suborder confirmation popup
   * and if the confirmConvertion is true it will call a convertion request
   * @param {boolean} state - State of the popup
   * @param {boolean} confirmConvertion
   */
  const setSaveAsOrderOpenConfirmationState = useCallback(
    (state, confirmConvertion, conf) => {
      setOpenSaveAsOrderConfirmation(state);
      if (confirmConvertion) {
        dispatch(saveAsOrder({ id: quoteId })).then((response) => {
          if (!response?.error && response.payload.customMsg !== 2) {
            alertNotification.handleOpen(
              "success-savign-as-sub-order",
              "success",
              "Order saved successfully",
              5000,
            )
          } else if (response.payload.customMsg === 2) {
            alertNotification.handleOpen(
              "create-suborder-successs",
              "danger",
              "Order saved with errors. There is an issue with the order cost.",
              5000,
            )
          }


          if (response.payload.customMsg === 1) {
            dispatch(printBol()).then((response) => {
              if (!response?.error && response.payload.customMsg !== 2) {
                alertNotification.handleOpen(
                  "success-savign-as-sub-order",
                  "success",
                  "Bol Printed Successfully!",
                  5000,
                )
              }
            })
          } else {
            alertNotification.handleOpen(
              "create-suborder-successs",
              "warning",
              "Bol not printed to warehouse.",
              5000,
            )
          }
          dispatch(resetQuoteId());
          navigate(endpoints.ORDER_ENTRY + "/order/" + response.payload.suborder);
        });
      }
    },
    [dispatch, quoteId],
  );

  /**
   * Function to analyze all the required fields in the form and
   * validate if all of them have been filled
   */
  const validateFields = () => {
    dispatch(
      setErrors({
        customer: {
          customerId,
          customerName,
        },
        orderDetails: {
          orderedBy: placed_by,
          customerPo: customer_po,
        },
        freight: {
          billOfLading,
          freightAmount,
          handlingCharge,
        },
        items,
        warehouseId: warehouse_id,
      }),
    );
  };

  const deletingProcess = () => {
    dispatch(checkBackordersProcess({})).then((response) => {
      if (!response?.error) {
        if (response.payload.length > 0) {
          const boMessage = response.payload.map((d) => (
            <div key={d.id}>{d.message}</div>
          ));

          setDeletionMessages([
            {
              id: "boMessage",
              content: boMessage,
            },
            {
              id: "deleteQuestion",
              content: "Are you sure you want to delete this sub-order?",
            },
          ]);
        } else {
          setDeletionMessages([
            {
              id: "deleteQuestion",
              content: "Are you sure you want to delete this sub-order?",
            },
          ]);
        }
        setOpenDeleteOrderConfirmation(true);
      }
    });
  };

  return (
    <Container className="py-4">
      <Row
        className={`${orderStatus === PENDING_ORDER_STATUS.id &&
          hold_status === DATE_ORDER_HOLD_STATUS.id &&
          subOrderId > 0
          ? ""
          : "align-items-center"
          }`}
      >
        <Col md={2}>
          <div>
            {!savedAsQuote && <SubOrderSelector />}
            {savedAsQuote && <QuoteSelector />}
          </div>
        </Col>
        <Col md={2}>
          <div>
            <span>WAREHOUSE: </span>
            <span className="ms-2">{warehouse_name}</span>
          </div>
          <div>
            <span>Entered By </span>
            <span>{took_by}</span>
          </div>
        </Col>
        <Col md={8}>
          <div className="buttons-sh">
            {((savedAsOrder && subOrderId) || savedAsQuote) && (
              <div className="pe-2">
                <Button
                  variant="primary"
                  disabled={items.length == 0}
                  onClick={() => {
                    setShowPrintPopup(true)
                  }}
                >
                  <span className="me-2">Print/Send Invoice</span>
                  <AdfScannerIcon />
                </Button>
              </div>
            )}

            {savedAsOrder && (
              <div className="pe-2">
                <StatusSelector />
              </div>
            )}
            <div className="d-flex">
              {((savedAsOrder && orderStatus !== OPEN_ORDER_STATUS.id) || savedAsQuote) && (
                <FaxEmail />
              )}
              {savedAsOrder && (
                <div >
                  <Button
                    variant="danger"
                    disabled={subOrdersLocked}
                    onClick={() => deletingProcess()}
                  >
                    <span className="me-2">Delete Order</span>
                    <AiFillDelete />
                  </Button>
                  <ConfirmationAlertBySteps
                    title="Process of deletion"
                    show={openDeleteOrderConfirmation}
                    close={() => setOpenDeleteOrderConfirmationState(false)}
                    steps={deletionMessages}
                    confirmationLabel="Delete"
                    handledCancelEvent={() =>
                      setOpenDeleteOrderConfirmationState(false)
                    }
                    handleConfirmEvent={() =>
                      setOpenDeleteOrderConfirmationState(false, true)
                    }
                  />


                </div>
              )}
              {subOrderId > 0 && savedAsOrder && (
                <div className="ms-2">
                  <Button
                    variant="primary"
                    disabled={shouldAutosave || items.length == 0 ||
                      [
                        COMPLETED_ORDER_STATUS.id,
                        INVOICED_ORDER_STATUS.id,
                        POSTED_ORDER_STATUS.id,
                      ].includes(subOrder.status) }
                    hidden={shouldAutosave}
                    onClick={() => {
                      if (subOrderId === 0) {
                        setShowStatusPopup(true);
                      } else {
                        validateFields();
                        setRegisterType("subOrder");
                      }
                    }}
                  >
                    <span className="me-2">Update</span>
                    <AiOutlineSave />
                  </Button>
                </div>
              )}

              {!savedAsOrder && !savedAsQuote && (
                <div className="ms-2">
                  <Button
                    variant="primary"
                    disabled={subOrdersLocked || items.length == 0}
                    onClick={() => {
                      if (subOrderId === 0) {
                        setShowStatusPopup(true);
                      } else {
                        validateFields();
                        setRegisterType("subOrder");
                      }
                    }}
                  >
                    <span className="me-2">Save</span>
                    <AiOutlineSave />
                  </Button>
                </div>
              )}
              {!savedAsOrder && !savedAsQuote && (
                <div className="ms-2">
                  <Button
                    variant="primary"
                    disabled={items.length == 0}
                    onClick={() => {
                      validateFields();
                      setRegisterType("quote");
                    }}
                  >
                    <span className="me-2">Save as Quote</span>
                    <AiOutlineSave />
                  </Button>
                </div>
              )}

              {!savedAsOrder && !savedAsQuote && (
                <div className="ms-2">
                  <Button
                    variant="danger"
                    className=""
                    disabled={subOrdersLocked}
                    onClick={() => {
                      if (subOrderId === 0) {
                        dispatch(setCleaningAll());
                      } else {
                        navigate(endpoints.ORDER_ENTRY + "/");
                      }
                    }}
                  >
                    <span className="me-2">Reset</span>
                    <AiOutlineClear />
                  </Button>
                </div>
              )}
              {savedAsQuote && (
                <div className="ms-2">
                  <Button
                    variant="primary"
                    disabled={items.length == 0}
                    onClick={() => setSaveAsOrderOpenConfirmationState(true)}
                  >
                    <span className="me-2">Save as Order</span>
                    <AiOutlineSave />
                  </Button>
                  <ConfirmationAlert
                    content="Are you sure you want to save this quote as order?"
                    show={openSaveAsOrderConfirmation}
                    handledCancelEvent={() =>
                      setSaveAsOrderOpenConfirmationState(false)
                    }
                    handleConfirmEvent={() =>
                      setSaveAsOrderOpenConfirmationState(false, true)
                    }
                  />
                </div>

              )}
            </div>
          </div>
        </Col>
      </Row>

      {!savedAsOrder && !savedAsQuote && (
        <Modal
          show={showStatusPopup}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => setShowStatusPopup(false)}
        >
          <Modal.Header className="p-5" closeButton>
            <Modal.Title>
              <h3 className="h5">Order Confirmation</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-5">
            <Row className="mt-5">
              <Col md={12}>
                <div className="mb-4">Select the Status</div>
              </Col>
              <StatusSelector />
            </Row>
            <div className="mt-5">
              A/R Balance information:{` `}
              <ARBalanceMessage />
            </div>
            <div>
              <BackOrderMessage />
            </div>
            <Row className="mt-5">
              <Col md={5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date"
                    className="muiDatePicker"
                    inputFormat="MM/DD/YYYY"
                    name="commentDate"
                    value={commentDate}
                    onChange={(newValue) => setCommentDate(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={formStyle.inputStyle}
                        InputLabelProps={formStyle.inputLabelProps}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={12}>
                <TextField
                  type="text"
                  label="Customer Comments"
                  name="name"
                  multiline
                  rows={4}
                  value={customerComment}
                  onChange={(e) => setCustomerComment(e.target.value)}
                  style={{
                    ...formStyle.inputStyle,
                  }}
                  InputLabelProps={formStyle.inputLabelProps}
                />
              </Col>
            </Row>
            <div className="mt-5 d-flex justify-content-end">
              <Button
                variant="primary"
                disabled={orderStatus === OPEN_ORDER_STATUS.id}
                onClick={() => {
                  dispatch(setCheckDuplicatedDisabled(true));
                  if (
                    orderStatus !== PENDING_ORDER_STATUS.id &&
                    hold_status !== DATE_ORDER_HOLD_STATUS.id
                  ) {
                    setShowStatusPopup(false);
                    validateFields();
                    setRegisterType("subOrder");
                  } else {
                    if (!subOrder.hold_date) {
                      alertNotification.handleOpen(
                        "holddate-error",
                        "danger",
                        "The hold date field is required",
                        5000,
                      );
                    } else {
                      setShowStatusPopup(false);
                      validateFields();
                      setRegisterType("subOrder");
                    }
                  }
                }}
              >
                Done
              </Button>

            </div>

          </Modal.Body>
        </Modal>
      )}
      <PrintItemPopup
        show={showPrintPopup}
        type={savedAsOrder ? "Order" : "Quote"}
        handleRadioClick={(e) => setSelectedPrint(e.target.id.toLowerCase())}
        handleCancelEvent={() => setShowPrintPopup(false)}
        handleSendInvoice={handleSendInvoice}
        hasInvoice={customer_invoice_num > 0}
        handleConfirmEvent={() => {
          dispatch(printToOffice({ method: selectedPrint, bol_num: subOrderId, quote_num: quoteId }));
          setShowPrintPopup(false)

        }

        }
      />
    </Container>

  );
}

export default SubHeading;
